import { QueryOptions, useTeamfightQuery } from "~/graphql";
import { RankedQueue, RiotPlatformRegion, RiotRankedDivision, RiotRankedTier } from "~/types/riot";
import { findSummonersQuery } from "~/graphql/query/findSummoners/findSummoners.query";

export interface SummonerSearchResult {
  uuid: string;
  puuid: string;
  summonerId: string;
  gameName: string;
  tagLine: string;
  summonerLevel: number;
  profileIconId: number;
  region: RiotPlatformRegion;
  rankedEntries: {
    tier: RiotRankedTier;
    division: RiotRankedDivision;
    leaguePoints: number;
    set: number;
    queueType: RankedQueue;
  }[];
}

export interface GetSummonerResult {
  findSummoners: {
    total: number;
    results: SummonerSearchResult[];
  };
}

interface Variables {
  searchTerm: string;
  skip?: number;
  take?: number;
}


export async function findSummoners(variables: Variables, options?: QueryOptions) {
  const result = await useTeamfightQuery<GetSummonerResult, Variables>({
    query: findSummonersQuery,
    variables,
    options
  });

  return {
    ...result,
    data: computed<{
      total: number,
      results: SummonerSearchResult[]
    }>(() => result.result.value?.findSummoners || { total: 0, results: [] })
  };
}