import { gql } from "@apollo/client/core";

export const findSummonersQuery = gql`
    query FindSummoners($searchTerm: String!, $skip: Int, $take: Int) {
        findSummoners(searchTerm: $searchTerm, skip: $skip, take: $take) {
            results {
                uuid
                puuid
                summonerId
                gameName
                tagLine
                summonerLevel
                profileIconId
                region
                rankedEntries {
                    tier
                    division
                    leaguePoints
                    set
                    queueType
                }
            }
            total
        }
    }
`;
